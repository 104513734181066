<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card class="card" :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户名称">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择客户名称"
                  style="width: 100%"
                  v-model="queryParam.customer_id"
                >
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete('all')">删除</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="id"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>

<script>
import { STable } from '@/components'
import { getWmsDataPage, deleteWmsDataInfo } from '@/api/wms'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      customerOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '进仓编号关键字',
          dataIndex: 'in_num_pattern'
        },
        {
          title: '最低收费',
          dataIndex: 'min_charge'
        },
        {
          title: '白班时间',
          dataIndex: 'day_work_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getWmsDataPage('in_charge_rule', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'InChargeRule' && this.queryParam) {
        newRoute.meta.title = '入库费用规则'
        if (newRoute.query.isEdit) {
          Object.assign(this.currentData, newRoute.params)
        } else if (newRoute.query.isEdit === false) {
          this.$refs.table.refresh()
        }
      }
    }
  },
  created() {
    // getCommonOptions('customer').then(v => {
    //   this.customerOps = v
    // })
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: 'InChargeRuleCur',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'InChargeRuleCur',
        params: record
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteWmsDataInfo('in_charge_rule', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style scoped>
  .right {
    padding-right: 5px;
    text-align: right
  }
  .center {
    text-align: center
  }
</style>
